.rf-violation-mapping {
  margin-top: 1rem;
  position: relative;

  .rf-violation-item {
    display: flex;
    margin-bottom: 1rem;

    &__drag {
      display: flex;
      position: absolute;
    }

    &__select {
      width: 95%;

      .rf-input__caption {
        padding-left: 3.4rem;
      }
    }
  }
}
