.rf-api-url-types {
  &__text {
    display: flex;
    justify-content: center;
  }
}

.rf-api-url-types lui-radiobutton-group {
  transform: scale(0.8); /* Decrease size */
  --lui-padding-x: 0rem;
  --lui-padding-y: 0rem;
}